@tailwind base;
@tailwind components;
.decoration-none {
    text-decoration: none;
}
.brand-link {
    @apply text-brand-green underline text-sm;
}

.brand-link:hover {
    @apply opacity-75;
}

.brand-button {
    @apply rounded-xl py-2 px-3 text-sm font-medium bg-brand-green text-white;
}

.brand-button:hover {
    @apply bg-opacity-75;
}

.brand-button:disabled {
    @apply bg-gray-200 text-gray-400;
}

.brand-button-secondary {
    @apply rounded-xl py-2 px-3 text-sm font-medium bg-brand-green bg-opacity-10 text-brand-green;
    /* background-color: rgba(190, 190, 50, 0.1); */
}

.brand-button-secondary:hover {
    @apply bg-opacity-5;
}

.brand-button-secondary:disabled {
    @apply bg-gray-200 text-gray-400;
}

.brand-button-tertiary {
    @apply rounded-xl py-2 px-3 text-sm font-medium bg-transparent text-gray-600 border-2 border-gray-600;
}

.brand-button-tertiary:hover {
    @apply bg-opacity-75 text-brand-green border-brand-green;
}

.brand-button-tertiary:disabled {
    @apply border-gray-200 text-gray-200;
}

.dateBlock.Disabled .dateInnerHolder {
    background-color: rgba(24, 24, 24, 0.04);
}

.dateBlock.Disabled .statusPill {
    background-color: #E1E1E1;
}

.dateBlock.Selected .statusPill {
    @apply bg-brand-green;
}

.dateBlock.Selected .dateInnerHolder {
    @apply bg-brand-green bg-opacity-20;
}

.dateBlock.Booked .statusPill {
    @apply bg-brand-green-dark;
}

.dateBlock.Booked .dateInnerHolder {
    @apply bg-brand-green-dark bg-opacity-20;
}

.dateBlock.Unavailable,
.dateBlock.Disabled {
    @apply cursor-not-allowed;
}

.dateBlock.Unavailable .dateInnerHolder {
    @apply bg-brand-red bg-opacity-20;
}

.dateBlock.Unavailable .statusPill {
    @apply bg-brand-red;
}

.brand-badge {
    @apply h-6 inline-flex items-center px-2.5 py-0.5 m-2 rounded-2xl text-xs font-medium text-black;
    background-color: rgba(24, 24, 24, 0.1);
}

.brand-badge:hover {
    @apply opacity-60;
}

.brand-badge.selected {
    @apply bg-brand-green;
}

.brand-badge.unselected {
    @apply opacity-20;
}

.brand-badge.removed {
    @apply bg-brand-green bg-opacity-40 opacity-60;
}

main {
    min-height: 58vw;
}

@tailwind utilities;

.page-break-before {
    page-break-before: always;
}

@media print {
    .page-break-before {
        page-break-before: always;
    }
}